import { ICard } from '../interfaces';
import Card from './Card';

type CardContainerProps = {
  cards: ICard[];
};

const CardContainer: React.FC<CardContainerProps> = ({ cards }): JSX.Element => {
  return (
    <section className="mx-auto flex h-fit self-center gap-4 flex-wrap justify-center">
      {cards.map((el, i) => (
        <Card key={`${el.rank}${el.suit}`} {...el} num={i + 1} />
      ))}
    </section>
  );
};

export default CardContainer;
