import confetti from 'canvas-confetti';
import { useEffect } from 'react';
import winner from '../assets/winner.svg';

const WinnerMessage: React.FC = (): JSX.Element => {
  useEffect(() => {
    confetti({
      particleCount: 150,
      spread: 400,
    });

    return () => {
      confetti.reset();
    };
  }, []);

  return (
    <div className="winner-animation absolute w-full top-[15%] p-2">
      <img src={winner} alt="Winner!" className="m-auto" />
    </div>
  );
};

export default WinnerMessage;
