const LoseMessage: React.FC = (): JSX.Element => {
  return (
    <h2 className="text-white font-prime text-lg text-center my-6">
      You Lose.
      <br />
      Better luck next time!
    </h2>
  );
};

export default LoseMessage;
