import Button from './components/Button';
import CardContainer from './components/CardContainer';
import Counters from './components/Counters';
import GameOverMessage from './components/GameOverMessage';
import LoseMessage from './components/LoseMessage';
import WinnerMessage from './components/WinnerMessage';
import useDeck from './hooks/useDeck';

function App() {
  const { dealtCards, reset, deal, cardCounter, acesLeft, isEmpty, hasAces } = useDeck();

  return (
    <main className="bg-[#0A863E] bg-body relative grid grid-rows-layout-desktop gap-2 h-screen p-8">
      <Counters
        value={[
          [cardCounter, 'Cards'],
          [acesLeft, 'Aces'],
        ]}
      />
      <CardContainer cards={dealtCards} />
      {isEmpty && (hasAces ? <WinnerMessage /> : <LoseMessage />)}
      {isEmpty ? (
        <Button
          onClick={reset}
          className="text-base py-1 px-6 text-[#EFCE4B] border-4 border-[#EFCE4B] mx-auto"
        >
          Play Again
        </Button>
      ) : (
        <>
          <Button onClick={deal} className="bg-[#EFCE4B] text-4xl uppercase pt-2 pb-1 px-8 mx-auto">
            Deal
          </Button>
          {acesLeft === 0 && <GameOverMessage />}
          <Button
            onClick={reset}
            className="text-base py-1 px-6 text-[#EFCE4B] border-4 border-[#EFCE4B] justify-self-end"
          >
            Reset
          </Button>
        </>
      )}
    </main>
  );
}

export default App;
