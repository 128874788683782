type CounterProps = {
  value: Array<[number, string]>;
};

const Counters: React.FC<CounterProps> = ({ value }): JSX.Element => {
  return (
    <div className="flex mx-auto">
      {value.map(([counter, desc]) => (
        <h1
          key={desc}
          className="bg-black flex flex-col flex-grow justify-center items-center border border-[#FFF48C] p-4 w-fit text-2xl text-white font-prime font-bold"
        >
          {counter}
          <p className="text-base">{desc} Left</p>
        </h1>
      ))}
    </div>
  );
};

export default Counters;
