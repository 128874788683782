import { useState, useRef } from 'react';
import { ICard } from '../interfaces';
import { deckBuilder } from '../utils';

const useDeck = () => {
  const [dealtCards, setDealtCards] = useState<ICard[]>([] as ICard[]);
  const deck = useRef<ICard[]>(deckBuilder());
  const aces = useRef<number>(4);

  const reset = () => {
    deck.current = deckBuilder();
    aces.current = 4;
    setDealtCards([]);
  };

  const deal = () => {
    const dealt = deck.current.splice(-5);
    aces.current -= dealt.filter((el) => el.rank === 'A').length;
    setDealtCards(dealt);
  };

  const hasAces = dealtCards.filter((el) => el.rank === 'A').length > 0;
  return {
    dealtCards,
    reset,
    deal,
    cardCounter: deck.current.length,
    acesLeft: aces.current,
    isEmpty: deck.current.length === 0,
    hasAces,
  };
};

export default useDeck;
