import shuffle from 'lodash/shuffle';
import clover from '../assets/Clover.svg';
import diamond from '../assets/Diamond.svg';
import heart from '../assets/Heart.svg';
import spade from '../assets/Spade.svg';
import { ranks, suits } from '../data';
import { ICard, ISuit } from '../interfaces';

const deckBuilder = (): ICard[] => {
  const deck = [] as ICard[];

  ranks.forEach((rank) => {
    suits.forEach((suit) => {
      const card: ICard = { rank, suit };
      deck.push(card);
    });
  });

  return shuffle(deck);
};

const getIconSrc = (suit: ISuit): string => {
  switch (suit) {
    case 'clover':
      return clover;
    case 'diamond':
      return diamond;
    case 'heart':
      return heart;
    case 'spade':
      return spade;
    default:
      return '';
  }
};

export { deckBuilder, getIconSrc };
