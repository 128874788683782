import { ICard } from '../interfaces';
import { getIconSrc } from '../utils';

type CardProps = ICard & { num: number };

const Card: React.FC<CardProps> = ({ suit, rank, num }): JSX.Element => {
  const iconSrc = getIconSrc(suit);
  return (
    <div
      className={`card remove-cards deal-${num} w-[7rem] aspect-[3/4] border shadow-md bg-white rounded-3xl p-4 flex flex-col font-prime font-bold`}
    >
      <div className="text-[2rem]">{rank}</div>
      <div className="relative flex justify-end items-end flex-grow">
        <img
          className="absolute w-[1.3rem] top-0 left-0"
          src={iconSrc}
          alt={`${suit} ${rank} small icon`}
        />
        <img className="w-[3rem]" src={iconSrc} alt={`${suit} ${rank} large icon`} />
      </div>
    </div>
  );
};

export default Card;
